import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useSponsors } from "@app/hooks";
import { EditableImage } from "@app/components";
import { isEmpty } from "@app/utils";

export interface SponsorsSectionProps {
  isEdit?: boolean;
  data?: {
    items: { link: string; image: any }[];
  };
}

export const SponsorsSection: FC<SponsorsSectionProps> = ({ isEdit, data }) => {
  const sponsors = isEdit ? data.items : useSponsors();
  const _isEmpty = isEmpty(isEdit, sponsors);

  if (_isEmpty) {
    return null;
  }

  return (
    <section className="text-center py-5">
      <Container>
        <Row className="justify-content-center align-items-center">
          {sponsors.map((s, i) => (
            <Col md={2} key={s.link + i}>
              <a href={s.link}>
                <EditableImage isEdit={isEdit} image={s.image} alt="" />
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
