import { useSettings } from "@app/hooks";
import React from "react";
import Helmet from "react-helmet";

interface MetaProp {
  name: string;
  content: string;
}

export const SEO = (props) => {
  const { title_suffix } = useSettings();
  const frontmatter = props?.data?.content?.childMarkdownRemark?.frontmatter;
  const seo_title = frontmatter?.seo_title;
  const seo_description = frontmatter?.seo_description;
  const meta: MetaProp[] = [{ name: "description", content: seo_description }];

  return (
    <Helmet title={seo_title} titleTemplate={`%s${title_suffix}`} meta={meta} />
  );
};
