import React from "react";
import ReactMarkdown from "react-markdown";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import { useSettings } from "@app/hooks";

import * as styles from "./index.module.scss";

export const Footer = () => {
  const { twitter_link, facebook_link, instagram_link, footer_text } =
    useSettings();

  const socialLinks = [
    { icon: faTwitter, link: twitter_link },
    { icon: faFacebook, link: facebook_link },
    { icon: faInstagram, link: instagram_link },
  ];

  return (
    <footer className={styles.footer}>
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <div className="w-100 d-flex justify-content-center">
              {socialLinks
                .filter((l) => l.link)
                .map((l) => (
                  <a key={l.link} className={styles.socialIcon} href={l.link}>
                    <FontAwesomeIcon icon={l.icon} className="mx-2" />
                  </a>
                ))}
            </div>
          </Col>
          <Col md={10} className={styles.copyright}>
            <small className="text-secondary small">
              <ReactMarkdown>{footer_text}</ReactMarkdown>
            </small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
