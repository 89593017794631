import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useMainSponsors } from "@app/hooks";
import { EditableImage } from "@app/components";
import { EditableMarkdown } from "../editable-markdown";
import { isEmpty } from "@app/utils";

export interface MainSponsorsSectionProps {
  isEdit?: boolean;
  data?: {
    body: any;
    items: { link: string; image: any }[];
  };
}

export const MainSponsorsSection: FC<MainSponsorsSectionProps> = ({
  isEdit,
  data,
}) => {
  let sponsors: { link: string; image: any }[], body: any;
  if (isEdit) {
    sponsors = data.items;
    body = data.body;
  } else {
    const { sponsors: s, body: b } = useMainSponsors();
    sponsors = s;
    body = b;
  }

  const _isEmpty = isEmpty(isEdit, sponsors);

  if (_isEmpty && !body) {
    return null;
  }

  return (
    <section className="text-center py-5">
      <Container>
        {body && (
          <Row>
            <Col>
              <EditableMarkdown isEdit={isEdit} markdown={body} />
            </Col>
          </Row>
        )}

        {!_isEmpty && (
          <Row className="justify-content-center align-items-center">
            {sponsors.map((s, i) => (
              <Col md={4} key={s.link + i}>
                <a href={s.link}>
                  <EditableImage isEdit={isEdit} image={s.image} alt="" />
                </a>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </section>
  );
};
