import React, { useState } from "react";
import { Footer, Header, SEO, Sidebar } from "@app/components";

export const Layout = ({ children, ...rest }) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <>
      <SEO {...rest} />
      <Header toggleSidebar={() => setOpenSidebar((v) => !v)} />
      <Sidebar isOpen={openSidebar} onClose={() => setOpenSidebar(false)} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
