import { graphql, useStaticQuery } from "gatsby";

export const useSettings = () => {
  const {
    content: {
      childMarkdownRemark: {
        frontmatter: {
          facebook_link,
          twitter_link,
          instagram_link,
          register_link,
          footer_text,
          title_suffix,
          logo,
        },
      },
    },
  } = useStaticQuery(graphql`
    query {
      content: file(
        sourceInstanceName: { eq: "content" }
        name: { eq: "settings" }
      ) {
        childMarkdownRemark {
          frontmatter {
            facebook_link
            twitter_link
            instagram_link
            register_link
            footer_text
            title_suffix
            logo {
              childImageSharp {
                gatsbyImageData(width: 250)
              }
            }
          }
        }
      }
    }
  `);

  return {
    register_link,
    facebook_link,
    twitter_link,
    instagram_link,
    footer_text,
    title_suffix,
    logo,
  };
};
