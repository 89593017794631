import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useOtherEvents } from "@app/hooks";
import { EditableImage, EditableMarkdown } from "@app/components";
import { isEmpty } from "@app/utils";

export interface OtherEventsSectionProps {
  isEdit?: boolean;
  data?: {
    body: any;
    items: { link: string; image: any }[];
  };
}

export const OtherEventsSection: FC<OtherEventsSectionProps> = ({
  isEdit,
  data,
}) => {
  let events, body;
  if (isEdit) {
    events = data.items;
    body = data.body;
  } else {
    const { events: e, body: b } = useOtherEvents();
    events = e;
    body = b;
  }

  const _isEmpty = isEmpty(isEdit, events);

  if (_isEmpty && !body) {
    return null;
  }

  return (
    <>
      {body && (
        <section className="text-center pt-5">
          <Container>
            <Row>
              <Col>
                <EditableMarkdown isEdit={isEdit} markdown={body} />
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {!_isEmpty && (
        <section className="text-center py-5">
          {events.map((s, i) => (
            <a href={s.link} key={s.link + i}>
              <EditableImage isEdit={isEdit} image={s.image} alt="" />
            </a>
          ))}
        </section>
      )}
    </>
  );
};
