import React, { FC } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "gatsby";
import cn from "classnames";

import { navItems } from "@app/config";

import * as styles from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Sidebar: FC<SidebarProps> = ({ isOpen, onClose }) => {
  const className = cn(styles.sidebar, "d-block", {
    [styles.sidebarOpen]: isOpen,
  });

  return (
    <Nav className={className}>
      <div className={styles.closeBtn} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} size="2x" />
      </div>
      {navItems.map((m) => (
        <Nav.Link
          as={Link}
          key={m.label}
          to={m.link}
          className={styles.navItem}
          activeClassName={styles.navItemActive}
          onClick={onClose}
        >
          {m.label}
        </Nav.Link>
      ))}
    </Nav>
  );
};
