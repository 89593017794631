import { graphql, useStaticQuery } from "gatsby";

export const useMainSponsors = () => {
  const {
    content: {
      childMarkdownRemark: {
        html,
        frontmatter: { items },
      },
    },
  } = useStaticQuery(graphql`
    query {
      content: file(
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "components" }
        name: { eq: "main_sponsors" }
      ) {
        childMarkdownRemark {
          html
          frontmatter {
            items {
              link
              image {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
        }
      }
    }
  `);

  return { body: html, sponsors: items } as {
    body: any;
    sponsors: { link: string; image: any }[];
  };
};
