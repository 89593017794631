// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-charity-index-tsx": () => import("./../../../src/pages/charity/index.tsx" /* webpackChunkName: "component---src-pages-charity-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-goodiebag-index-tsx": () => import("./../../../src/pages/goodiebag/index.tsx" /* webpackChunkName: "component---src-pages-goodiebag-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prepare-index-tsx": () => import("./../../../src/pages/prepare/index.tsx" /* webpackChunkName: "component---src-pages-prepare-index-tsx" */)
}

