import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext } from "react";
import {
  Accordion as BAccordion,
  AccordionProps as BAccordionProps,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import cn from "classnames";

import * as styles from "./index.module.scss";

export interface AccordionItem {
  header: string;
  body: JSX.Element;
}

export interface AccordionProps {
  accordionProps?: BAccordionProps;
  items: AccordionItem[];
}

export const Accordion: FC<AccordionProps> = ({ items, accordionProps }) => {
  return (
    <BAccordion defaultActiveKey="0" {...accordionProps}>
      {items.map((item, i) => (
        <div className={styles.accordionItem} key={item.header.toString() + i}>
          <AccordionHeader eventKey={`${i}`}>
            <strong>{item.header}</strong>
          </AccordionHeader>
          <BAccordion.Collapse eventKey={`${i}`}>
            <div className={styles.accordionBody}>{item.body}</div>
          </BAccordion.Collapse>
        </div>
      ))}
    </BAccordion>
  );
};

const AccordionHeader = ({ children, eventKey, callback }: any) => {
  const { activeEventKey } = useContext(AccordionContext);
  const isCurrentEventKey = activeEventKey === eventKey;

  const handleClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const className = cn(styles.accordionHeader, {
    [styles.accordionHeaderActive]: isCurrentEventKey,
  });

  return (
    <button type="button" onClick={handleClick} className={className}>
      <div className={styles.collapseIcon}>
        <FontAwesomeIcon
          icon={isCurrentEventKey ? faMinusCircle : faPlusCircle}
          color="#888"
        />
      </div>
      {children}
    </button>
  );
};
