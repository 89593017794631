import React, { FC } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Navbar, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import cn from "classnames";

import { useSettings } from "@app/hooks";
import { navItems } from "@app/config";

import * as styles from "./index.module.scss";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export interface HeaderProps {
  toggleSidebar: () => void;
}

export const Header: FC<HeaderProps> = ({ toggleSidebar }) => {
  const { logo, twitter_link, facebook_link, instagram_link, register_link } =
    useSettings();

  const socialLinks = [
    { icon: faTwitter, link: twitter_link },
    { icon: faFacebook, link: facebook_link },
    { icon: faInstagram, link: instagram_link },
  ];

  return (
    <>
      <Navbar className="bg-secondary text-white">
        <Container>
          <div className="d-flex">
            {socialLinks
              .filter((l) => l.link)
              .map((l) => (
                <a key={l.link} href={l.link}>
                  <FontAwesomeIcon
                    icon={l.icon}
                    color="#fff"
                    className="mx-2"
                  />
                </a>
              ))}
          </div>
          <div className="ms-auto">
            {register_link && (
              <a href={register_link} className="text-white small">
                REGISTER NOW
              </a>
            )}
          </div>
        </Container>
      </Navbar>
      <Navbar>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <GatsbyImage image={getImage(logo)} alt="logo" />
          </Navbar.Brand>
          <div
            onClick={toggleSidebar}
            className={cn("d-lg-none", styles.sidebarToggle)}
          >
            <FontAwesomeIcon icon={faBars} size="2x" />
          </div>
          <Nav className="ms-auto d-none d-lg-flex">
            {navItems.map((m) => (
              <Nav.Link
                as={Link}
                key={m.label}
                to={m.link}
                className={styles.navItem}
                activeClassName="active"
              >
                {m.label}
              </Nav.Link>
            ))}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};
