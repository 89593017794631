import React, { FC } from "react";
import { GatsbyImage, GatsbyImageProps, getImage } from "gatsby-plugin-image";

export interface EditableImageProps extends GatsbyImageProps {
  isEdit: boolean;
  image: any;
}

export const EditableImage: FC<EditableImageProps> = ({
  isEdit,
  image,
  alt,
  ...rest
}) => {
  if (!image) return null;
  const srcPath = isEdit
    ? image.toString()
    : image.childImageSharp.gatsbyImageData.images.fallback.src;
  if (srcPath.includes("placeholder.png")) return null;

  return isEdit ? (
    <img src={image.toString()} alt={alt} className="img-fluid" />
  ) : (
    <GatsbyImage image={getImage(image.childImageSharp)} alt={alt} {...rest} />
  );
};
