import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export interface EditableMarkdownProps {
  isEdit: boolean;
  markdown: any;
}

export const EditableMarkdown: FC<EditableMarkdownProps> = ({
  isEdit,
  markdown,
}) => {
  if (!markdown) return null;
  return isEdit ? (
    markdown
  ) : (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markdown}</ReactMarkdown>
  );
};
